import { debounce } from '@/lib/helpers'
import { Controller } from '@hotwired/stimulus'
import { navigator } from '@hotwired/turbo'

const PERMITTED_EVENTS = ['change', 'input']

export default class extends Controller {
  static targets = ['input', 'submit']
  static outlets = [ 'navbar' ]

  initialize() {
    this.submit = debounce(this.submit.bind(this), 500)
  }

  connect() {
    this.checkValidity()
    this.formAction = this.element.action
  }

  checkValidity() {
    const disabled = this.invalidForm
    this.submitTargets.forEach(target => target.disabled = disabled)
  }

  submit(event) {
    const form = event.target.form || event.target.closest('form')
    if (form) this.formatAndSubmit(form, event)
  }

  formatAndSubmit(form, event) {
    this.toggleNotification()

    if (PERMITTED_EVENTS.includes(event.type)) {
      form.action = this.formAction.concat('.json')
    }

    navigator.submitForm(form)
    form.action = this.formAction
  }

  toggleNotification() {
    this.navbarOutlet.toggleNotification()
  }

  get invalidForm() {
    return !this.inputTargets.every(target => target.validity.valid)
  }
}