import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import HwComboboxController from '@josefarias/hotwire_combobox'

const application = Application.start()

application.debug = false
window.Stimulus = application

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
application.register('hw-combobox', HwComboboxController)

registerControllers(application, controllers)
