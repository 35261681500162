import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['input']
  static values = { url: String }

  search() {
    const url = `${this.urlValue}?usdot=${this.inputTarget.value}`
    Turbo.visit(url, { frame: 'fields-container' })
  }
}