
import { BridgeComponent } from '@hotwired/strada'

export default class extends BridgeComponent {
  static component = 'cancel-button'

  connect() {
    super.connect()

    const button = this.bridgeElement
    const title = "Cancel"

    this.send('connect', { title }, () => {
      button.click()
    })
  }
}
