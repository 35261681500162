import Autocomplete from './autocomplete_controller'
import SelectedOption from './selected_option_controller'

export default class extends Autocomplete {
  static targets = ['selectedOptions']

  static values = {
    multi: Boolean,
  }

  connect() {
    this.close()

    if (!this.inputTarget.hasAttribute('autocomplete')) this.inputTarget.setAttribute('autocomplete', 'off')
    this.inputTarget.setAttribute('spellcheck', 'false')

    this.mouseDown = false

    if (!this.hasOptionsValue) this.onInputChange = debounce(this.onInputChange, this.delayValue)

    this.inputTarget.addEventListener('keydown', this.onKeydown)
    this.inputTarget.addEventListener('blur', this.onInputBlur)
    this.inputTarget.addEventListener('input', this.onInputChange)
    this.resultsTarget.addEventListener('mousedown', this.onResultsMouseDown)
    this.resultsTarget.addEventListener('mouseover', this.onResultsMouseOver)
    this.resultsTarget.addEventListener('click', this.onResultsClick)
    this.inputTarget.addEventListener('click', this.open)

    if (this.inputTarget.hasAttribute('autofocus')) {
      this.inputTarget.focus()
    }

    this.readyValue = true
  }

  onInputChange = () => {
    this.element.removeAttribute('value')
    if (this.hasHiddenTarget) this.hiddenTarget.value = ''

    const query = this.inputTarget.value.trim()
    if (query && query.length >= this.minLengthValue) {
      this.fetchResults(query)
    } else {
      this.showLocalOptions()
    }
  }

  commit(selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute('data-autocomplete-label') || selected.textContent.trim()
    const value = selected.getAttribute('data-autocomplete-value') || textValue
    this.inputTarget.value = textValue

    if (this.multiValue) {
      SelectedOption.addTo(this.selectedOptionsTarget, this.hiddenTarget.name, value, textValue)
      this.inputTarget.value = ''
    } else if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event('input'))
      this.hiddenTarget.dispatchEvent(new Event('change'))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent('autocomplete.change', {
        bubbles: true,
        detail: { value: value, textValue: textValue, selected: selected },
      }),
    )
  }

  showLocalOptions() {
    if (!this.hasOptionsValue) return
    this.resultsTarget.innerHTML = this.optionsValue.map(o => this.decorateResult(o)).join('\n')
  }

  open = () => {
    if (this.resultsShown) return

    this.showLocalOptions()
    this.resultsShown = true
    this.element.setAttribute('aria-expanded', 'true')
    this.element.dispatchEvent(
      new CustomEvent('toggle', {
        detail: { action: 'open', inputTarget: this.inputTarget, resultsTarget: this.resultsTarget },
      }),
    )
  }
}

const debounce = (fn, delay = 10) => {
  let timeoutId = null

  // eslint-disable-next-line no-unused-vars
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(fn, delay)
  }
}