import { Controller } from '@hotwired/stimulus'
import { humanizedDuration } from '@/lib/helpers'

export default class extends Controller {
  static targets = ['deadheadMiles', 'loadedMiles', 'duration']
  static values = { form: String }

  initialize() {
    this.summary = []
  }

  render({ detail: { summary } }) {
    this.summary = summary
    this.updateElements()
    this.updateFormFields()
  }

  updateElements() {
    this.deadheadMilesTarget.textContent = `${this.deadheadMiles } mi`
    this.loadedMilesTarget.textContent = `${this.loadedMiles } mi`
    this.durationTarget.textContent = this.duration
  }

  updateFormFields() {
    if (!this.hasFormValue) return

    const formElement = document.querySelector(`[data-heremap-form="${this.formValue}"]`)

    if (formElement) {
      const fields = formElement.elements
      fields.namedItem(`${this.formValue}_deadhead_miles`).value = this.deadheadMiles
      fields.namedItem(`${this.formValue}_loaded_miles`).value = this.loadedMiles
    }
  }

  get deadheadMiles() {
    if (this.summary.length < 2) return 0
    const miles = this.summary[0].length * 0.000621
    return miles.toFixed(1)
  }

  get loadedMiles() {
    if (this.summary.length < 2) return 0
    const [, ...loaded] = this.summary
    const meters = loaded.reduce((value, load) => value + load.length, 0)

    const miles = isNaN(meters) ? meters.length * 0.000621 : meters * 0.000621

    return miles.toFixed(1)
  }

  get duration() {
    if (this.summary.length < 2) return 0
    const durationInSeconds = this.summary.reduce((value, summary) => value + summary.typicalDuration, 0)
    return humanizedDuration(durationInSeconds)
  }
}