import { Controller } from '@hotwired/stimulus'

const TRANSACTIONS_FRAME_ID = 'driver_transactions'

export default class extends Controller {
  static targets = ['driverSelect', 'transactionsContainer', 'transactionItem', 'modalOptions']
  connect () {
    this.initializeListeners()
  }

  initializeListeners() {
    this.driverSelectTarget.addEventListener('change', this.executeTurboFrame.bind(this))
  }

  modalOptionsTargetConnected() {
    this.disableSubmitBtn()
  }

  validateTransactionItems() {
    if (this.hasTransactionItemTarget) {
      let valid = false
      this.transactionItemTargets.forEach($checkbox => {
        if ($checkbox.checked) valid = true
      })
      if (valid) {
        this.enableSubmitBtn()
      } else {
        this.disableSubmitBtn()
      }
    }
  }

  enableSubmitBtn() {
    const $submit = this.submitButton
    if ($submit) {
      $submit.removeAttribute('disabled')
    }
  }

  disableSubmitBtn() {
    const $submit = this.submitButton
    if ($submit) {
      $submit.setAttribute('disabled', true)
    }
  }

  executeTurboFrame() {
    this.resetTransactionsContainer()
    const driverId = this.driverSelectTarget.value
    if (driverId == '') return false
    const $transactionsFrame = this.turboFrame(TRANSACTIONS_FRAME_ID, `/drivers/${driverId}/unpaid_transactions` )
    this.transactionsContainerTarget.appendChild($transactionsFrame)
  }

  resetTransactionsContainer() {
    this.transactionsContainerTarget.innerHTML = ''
  }

  turboFrame (name, src) {
    const $frame = document.createElement('turbo-frame')

    $frame.setAttribute('id', name)
    $frame.setAttribute('src', src)

    return $frame
  }

  get submitButton() {
    return this.modalOptionsTarget.querySelector('button[type="submit"]')
  }

}