import { Controller } from '@hotwired/stimulus'
import { MaskInput } from 'maska'

export default class extends Controller {

  connect() {
    this.unmaskedInput = this.createUnmaskedInput()
    this.mask = new MaskInput(this.element, {
      mask: '0.99',
      tokens: {
        0: { pattern: /[0-9]/, multiple: true },
        9: { pattern: /[0-9]/, optional: true },
      },
      preProcess: val => val.replace(/[$,]/g, ''),
      postProcess: val => {
        if (!val) return ''

        const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)

        return Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(val).slice(0, sub ? -sub : undefined)
      },
      onMaska: (value) => {
        this.unmaskedInput.value = value.masked
      },
    })
  }

  createUnmaskedInput() {
    const input = Object.assign(document.createElement('input'), {
      type: 'hidden',
      name: this.element.name,
      value: this.element.value,
    })

    this.element.insertAdjacentElement('afterend', input)
    return input
  }

  disconnect() {
    this.mask?.destroy()
    this.unmaskedInput?.remove()
  }
}