import H from '@here/maps-api-for-javascript'

const HERE_API_KEY = 'ijMuwZHfHm8JuIAKvnLyy2q8cIQXLy6A3symQHxXal0'

class HereService {
  constructor() {
    if (HereService._instance) {
      return HereService._instance
    }
    HereService._instance = this

    this.initializeServices()
  }

  initializeServices() {
    // Initialize platform
    this.platformService = new H.service.Platform({
      apiKey: HERE_API_KEY,
    })

    // Initialize router and search services
    this.routerService = this.platform.getRoutingService(null, 8)
    this.searchService = this.platform.getSearchService()
  }

  get platform() {
    return this.platformService
  }

  get router() {
    return this.routerService
  }

  get search() {
    return this.searchService
  }
}

export const {
  platform,
  router,
  search,
} = Object.freeze(new HereService())
