import { DuetDatePicker } from '@duetds/date-picker/custom-element'

customElements.define('duet-date-picker', DuetDatePicker)

class DriveDatePicker extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    customElements.whenDefined('duet-date-picker').then(() => {
      this.duetPicker = this.querySelector('duet-date-picker')
      this.input = this.findOrCreateInput()

      this.setPickerFormatAndvalue()
      this.duetPicker.addEventListener('duetChange', this.duetPickerChanged.bind(this))
    })
  }

  disconnectedCallback() {
    this.duetPicker.removeEventListener('duetChange', this.duetPickerChanged)
  }

  duetPickerChanged(event) {
    this.value = event.detail.value
  }

  setPickerFormatAndvalue() {
    this.duetPicker.required = this.required
    this.duetPicker.localization.placeholder = 'mm/dd/yyyy'

    this.duetPicker.dateAdapter.parse = (value = '', createDate) => {
      const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/

      const matches = value.match(DATE_FORMAT_US)
      if (matches) return createDate(matches[3], matches[1], matches[2])
    }

    this.duetPicker.dateAdapter.format = (date) => {
      return Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)
    }
  }

  findOrCreateInput() {
    const input = this.querySelector('input[type=hidden]')

    if (input) {
      return input
    } else {
      const input = document.createElement('input')
      input.type = 'date'
      input.required = this.required
      input.min = this.min
      input.name = this.name
      input.classList.add('sr-only', 'left-1/2')
      input.value = this.value || ''
      this.appendChild(input)
      return input
    }
  }

  get name() {
    return this.getAttribute('name')
  }

  get min() {
    return this.duetPicker.getAttribute('min')
  }

  get required() {
    return this.hasAttribute('required')
  }

  set required(value) {
    this.duetPicker.required = !!value,
    this.toggleAttribute('required', !!value)
  }

  get value() {
    return this.getAttribute('value')
  }

  set value(value) {
    if (value != null) {
      this.duetPicker.value = value
      this.setAttribute('value', value)
      this.input.value = value
    } else {
      this.duetPicker.value = null
      this.removeAttribute('value')
      this.input.value = null
    }

    this.dispatchEvent(new Event('drive:date-change', {
      bubbles: true,
    }))
  }
}

customElements.define('drive-date-picker', DriveDatePicker)

class securityBlocks extends HTMLElement {
  constructor() {
    super()
    this.code = ''
    this.LEFT_ARROW_CODE = 37
    this.RIGHT_ARROW_CODE = 39
    this.BACKSPACE_CODE = 8
    this.inputEvents = {
      insertText: this.validateField,
      deleteContentBackward: this.deleteEventCode,
    }
    this.codeFieldTargets = this.querySelectorAll('input[data-target=block]')
    this.codeTarget = this.querySelector('input[data-target=code]')
  }

  initializeInputsCode() {

    if (this.hasCodeFieldTarget()) {
      this.codeFieldTargets.forEach( (element) => {
        if (!element.hasAttribute('type')) {
          element.setAttribute('type', 'tel')
        }
        element.setAttribute('maxlength', 1)
        element.setAttribute('autocomplete', 'off')
        element.setAttribute('spellcheck', false)
        element.setAttribute('required', true)
        element.addEventListener('input', this.validateEvents.bind(this))
        element.addEventListener('paste', this.pasteEventCode.bind(this))
        element.addEventListener('keyup', this.moveFocus.bind(this))
      })
    }
  }

  hasCodeFieldTarget() {
    return this.codeFieldTargets.length > 0
  }

  hasCodeTarget() {
    return this.codeTarget.length > 0
  }

  connectedCallback() {
    this.initializeInputsCode()
    this.initializeCodeField()
  }

  initializeCodeField() {
    if (this.hasCodeTarget) {
      const codeField = this.codeTarget
      if (!codeField.hasAttribute('type')) codeField.setAttribute('type', 'hidden')
      if (codeField.getAttribute('type') != 'hidden') codeField.setAttribute('type', 'hidden')
    }
  }

  validateEvents(event) {
    const context = this
    if (typeof this.inputEvents[event.inputType] === 'undefined') return
    this.inputEvents[event.inputType](event, context)
  }

  validateField(event, _context) {
    if (_context.isInvalid(event)) {
      event.target.value = ''
      return
    }
    _context.setCode()
    const next = event.target.nextElementSibling
    if (event.data === null || event.data === ' ') return
    if (_context.code.length === _context.codeFieldTargets.length) {
      _context.codeTarget.value = _context.code
      _context.emitCompleteCodeEvent(_context)
    } else {
      _context.cleanCodeField(_context)
    }
    if (next === null || next.getAttribute('data-target') == 'code') return
    event.target.nextElementSibling.focus()
  }

  emitCompleteCodeEvent(_context) {
    let event = new CustomEvent('security-blocks:completed', {
      detail: { code: _context.code },
      bubbles: true,
      composed: true,
    })
    _context.dispatchEvent(event)
  }

  isInvalid(event) {
    if (event.type == 'paste') return false
    if (this.hasAttribute('type') && this.getAttribute('type') == 'number') {
      const regex = /\D/g
      return regex.test(event.data)
    }
    return false
  }

  pasteEventCode(event) {
    const data = event.clipboardData.getData('text')
    let arrayLength = this.codeFieldTargets.length
    const arrayCode = Array.apply(null, Array(arrayLength))
    const clipboardArrayCode = data.toString().split('').slice(0, arrayLength)
    arrayCode.forEach((value, index) => {
      if (this.hasAttribute('type') && this.getAttribute('type') == 'number') {
        event.preventDefault()
        const regex = /\D/g
        if (regex.test(clipboardArrayCode[index])) {
          arrayLength = 1
          return false
        }
        this.codeFieldTargets[index].value = !regex.test(clipboardArrayCode[index]) ? clipboardArrayCode[index] : ' '
      } else {
        this.codeFieldTargets[index].value = clipboardArrayCode[index] ? clipboardArrayCode[index] : ' '
      }
    })
    this.validateField(event, this)
    const lastChild = this.codeFieldTargets[arrayLength - 1]
    lastChild.focus()
  }

  deleteEventCode(event, _context) {
    const previous = event.target.previousElementSibling
    if (event.target.value != '') return
    _context.setCode()
    _context.cleanCodeField(_context)
    if (previous === null) return
    previous.focus()
    previous.select()
  }

  moveFocus(event) {
    const next = event.target.nextElementSibling
    const previous = event.target.previousElementSibling
    if (event.keyCode == this.LEFT_ARROW_CODE || event.target.value == '' && event.keyCode == this.BACKSPACE_CODE) {
      if (previous === null) return
      previous.focus()
      previous.select()
      return
    }
    if (event.keyCode == this.RIGHT_ARROW_CODE) {
      if (next === null || next.getAttribute('data-target') == 'code') return
      next.focus()
      next.select()
      return
    }
  }

  setCode() {
    let arrayCode = []
    this.codeFieldTargets.forEach((element, index) => {
      if (this.hasAttribute('type') && this.getAttribute('type') == 'number') {
        arrayCode[index] = element.value.replace(/\D/g, '')
      } else {
        arrayCode[index] = element.value
      }
    })
    this.code = arrayCode.join('').trim()
  }

  cleanCodeField(_context) {
    _context.codeTarget.value = ''
  }
}

window.customElements.define('security-blocks', securityBlocks)
