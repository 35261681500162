import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  // This function handles the form submission and checks if the form has been
  // submitted with the 'approved' stage. If so, it loads the contract terms
  // into the modal frame.
  handleSubmit({ detail }) {
    const stage = detail.formSubmission.formData.get('stage')

    if (stage == 'approved') {
      this.modalTurboFrame.src = this.contractMethodUrl
      this.modalTurboFrame.reload()
    }
  }

  get modalTurboFrame() {
    return document.getElementById('modal')
  }

  get contractMethodUrl () {
    return this.element.dataset.contractMethodUrl
  }
}