import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    if (this.element.querySelector('input[type="checkbox"]').value == 'true') {
      if (this.contentTarget) this.contentTarget.classList.remove('hidden')
    }
  }

  toggle() {
    this.contentTarget.classList.contains('hidden') ? this.contentTarget.classList.remove('hidden') : this.contentTarget.classList.add('hidden')
  }

}