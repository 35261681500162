import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'checkContent', 'contentChecked', 'contentUnchecked', 'additionalSecondAddress', 'additionalThirdAddress']
  
  LOCAL_STORAGE_KEY = 'form'

  NEW_FORM_OBJECT = {
    'question_legal_right_work': false,
    'question_employed': false,
    'question_bonded': false,
    'question_convicted_felony': false,
    'question_perform_apply': false,
    'question_license_another_state': false,
    'question_denied_license': false,
    'question_has_license_permit': false,
    'question_current_address': false,
    'additional_second_address': false,
    'additional_third_address': false,
    'day1': 0,
    'day2': 0,
    'day3': 0,
    'day4': 0,
    'day5': 0,
    'day6': 0,
    'day7': 0,
    'total': 0,
    'howlong1': '',
    'street2': '',
    'city2': '',
    'state2': '',
    'zip2': '',
    'howlong2': '',
    'street3': '',
    'city3': '',
    'state3': '',
    'zip3': '',
    'howlong3': '',
  }

  connect() {
    this.storage == null ? this.setStorage(this.NEW_FORM_OBJECT) : this.assignValuesToInputs()

    if (this.checkContentTarget.value == 'true') {
      this.contentCheckedTarget.classList.remove('hidden')
      this.contentUncheckedTarget.classList.add('hidden')
    }

    if (parseInt(this.storage['howlong1']) < 36) {
      this.additionalSecondAddressTarget.classList.remove('hidden')
    }

    if (parseInt(this.storage['howlong2']) < 36) {
      this.additionalThirdAddressTarget.classList.remove('hidden')
    }

    this.element.addEventListener('submit', this.deleteLocalStorage.bind(this))
  }

  toggleAndHide() {
    this.contentCheckedTarget.classList.contains('hidden') ? this.contentCheckedTarget.classList.remove('hidden') : this.contentCheckedTarget.classList.add('hidden')
    this.contentUncheckedTarget.classList.contains('hidden') ? this.contentUncheckedTarget.classList.remove('hidden') : this.contentUncheckedTarget.classList.add('hidden')
  }

  showHideAdditionalAddress({ target }) {
    let inputValue = parseInt(target.value)
    let nextTarget = `this.${target.dataset.next}Target`

    if (inputValue < 36) {
      eval(nextTarget).classList.remove('hidden')
      this.saveStatus(target.dataset.next, true)
    }
    if (isNaN(inputValue) || inputValue >= 36) {
      eval(nextTarget).classList.add('hidden')
    }
  }

  deleteAdditionalAddress({ target }) {
    let parentElement = target.closest('div').parentElement
    parentElement.classList.contains('hidden') ? parentElement.classList.remove('hidden') : parentElement.classList.add('hidden')
    this.saveStatus(parentElement.dataset.employmentApplicationModalTarget, false)
    this.deleteThirdAddress(parentElement)
  }

  deleteThirdAddress(parentElement) {
    if (parentElement.dataset.employmentApplicationModalTarget == 'additionalSecondAddress' && !this.additionalThirdAddressTarget.classList.contains('hidden')) this.additionalThirdAddressTarget.classList.add('hidden')
    this.saveStatus('additionalThirdAddress', false)
  }
  
  saveStatus(target, status) {
    let key = target.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)
    let storage = { ...this.storage }
    storage[key] = status
    this.setStorage(storage)
  }

  saveLocalStorage({ target }) {
    if (target.type == 'checkbox') target.value = target.checked
    let storage = { ...this.storage }
    let objectKey = target.dataset.key
    storage[objectKey] = target.value
    this.setStorage(storage)
    if (objectKey.startsWith('day')) this.assignTotalValue(storage)
  }
  
  deleteLocalStorage() {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY)
  }

  assignTotalValue(storage) {
    const keys = Object.keys(storage).filter(key => key.startsWith('day'))
    const values = keys.map(key => parseInt(storage[key])).filter(value => !isNaN(value))
    const sum = values.reduce((acc, curr) => acc + curr, 0)

    const inputTotal = document.querySelector('input[name="form[total]"]')
    inputTotal.value = sum

    storage['total'] = sum
    this.setStorage(storage)
  }

  assignValuesToInputs() {
    Object.getOwnPropertyNames(this.storage).forEach(key => {
      let nameInput = `form[${key}]`
      let input = this.inputs.find((element) => element.getAttribute('name') == nameInput)
      if (input !== undefined) input.value = this.storage[key]
    })

    this.markAsCheckedInputs()
    this.assignSelectValues()
  }

  markAsCheckedInputs() {
    let checkBoxes = this.inputs.filter(input => input.type == 'checkbox')
    checkBoxes.map((checkbox) => {
      checkbox.value = this.storage[checkbox.dataset.key]
      if (checkbox.value == 'true') checkbox.checked = true
    })
  }

  assignSelectValues() {
    let selects = this.selects.filter((element) => element.getAttribute('name').startsWith('form[state'))
    selects.map((select) => {
      Array.from(select.options).find((option) => {
        if (option.value == this.storage[select.dataset.key]) option.selected = true
      })
    })
  }

  get inputs() {
    return Array.from(this.formTarget.querySelectorAll('input'))
  }

  get selects() {
    return Array.from(this.formTarget.querySelectorAll('select'))
  }

  get storage() {
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY))
  }

  setStorage(object) {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(object))
  }
}