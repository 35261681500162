import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'filename', 'filesize', 'description', 'fileDetails', 'remove', 'removeInput']

  upload({ target }) {
    const file = target.files[0]

    this.removeInput = false
    this.toggleFileDetails()
    this.toggleRemoveButton()

    this.filenameTarget.textContent = file.name
    this.filesizeTarget.textContent = this.fileSize(file.size)
  }

  remove() {
    this.inputTarget.value = null
    this.removeInput = true
    this.toggleFileDetails()
    this.toggleRemoveButton()
  }

  // private

  toggleFileDetails() {
    const descriptionVisible = this.descriptionTarget.dataset.visible
    const fileDetailsVisible = this.fileDetailsTarget.dataset.visible

    this.fileDetailsTarget.dataset.visible = fileDetailsVisible === 'false' ? 'true' : 'false'
    this.descriptionTarget.dataset.visible = descriptionVisible === 'false' ? 'true' : 'false'
  }

  toggleRemoveButton() {
    const removeButtonVisible = this.removeTarget.dataset.visible
    this.removeTarget.dataset.visible = removeButtonVisible === 'false' ? 'true' : 'false'
  }

  set removeInput(value) {
    if (this.hasRemoveInputTarget) this.removeInputTarget.value = value
  }

  fileSize(number) {
    if (number < 1024) {
      return `${number} bytes`
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`
    }
  }

  get modalTurboFrame() {
    return document.getElementById('modal')
  }
}