import { Controller } from '@hotwired/stimulus'
import { debounce } from '@/lib/helpers'

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 250 },
  }

  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), this.delayValue)
  }

  submit() {
    this.formElement.requestSubmit()
  }

  debouncedSubmit() {
    this.submit()
  }

  // Private

  get formElement() {
    if (this.element instanceof HTMLFormElement) {
      return this.element
    } else {
      return this.element.form
    }
  }
}