import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['overlay', 'body', 'container']

  connect() {
    this.toggleBodyScroll()

    enter(this.overlayTarget)
    enter(this.bodyTarget)

    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  handleSubmit = (e) => {
    if (e.target.dataset.closeModal === 'false') return
    if (e.detail.success) this.close()
  }

  close() {
    this.toggleBodyScroll()

    leave(this.overlayTarget)
    leave(this.bodyTarget).then(() => {
      this.element.remove()
    })

    this.element.closest('turbo-frame').src = undefined
  }

  closeOnClickOutside({ target }) {
    if (this.containerTarget === target) this.close()
  }

  toggleBodyScroll() {
    document.body.classList.toggle('overflow-hidden')
  }
}