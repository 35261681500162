import H from '@here/maps-api-for-javascript'
import { platform, router } from '@/lib/heremap/service'
import { calculateRoute } from '@/lib/heremap/helpers'

const DEFAULT_CENTER_COORDINATES = { lat: 40.17545, lng: -95.24927 }

export class Map {
  static fromController(controller) {
    const map = new Map(controller)
    return map
  }

  constructor(controller) {
    this.controller = controller
    this.mapElement = controller.mapTarget
    this.stopsElement = controller.stopsTarget
  }

  initialize() {
    // Initialize and display a map object
    const layers = platform.createDefaultLayers()

    layers.vector.normal.map.setMin(5)

    this.map = new H.Map(this.mapElement, layers.vector.normal.map, {
      zoom: 6,
      center: this.coordinates?.[0] || DEFAULT_CENTER_COORDINATES,
      pixelRatio: window.devicePixelRatio || 1,
    })

    // Add a resize listener to the map container
    window.addEventListener('resize', () => this.map.getViewPort().resize())

    // Add default interactions for pan/zoom
    new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map))

    // Add UI components
    H.ui.UI.createDefault(this.map, layers)

    this.routePolyline = null
    this.markerGroup = null
  }

  render() {
    if (this.coordinates.length <= 1) {
      this.resetMapElements()

      if (this.coordinates.length) {
        this.map.setCenter(this.coordinates[0])
        this.map.setZoom(16)
      }
    } else {
      this.requestRoute()
    }
  }

  async requestRoute() {
    const routingParameters = {
      routingMode: 'fast',
      transportMode: 'truck',
      origin: this.origin,
      destination: this.destination,
      return: 'polyline,summary,typicalDuration',
    }

    if (this.via.length > 0) {
      routingParameters.via = new H.service.Url.MultiValueQueryParameter(this.via)
    }

    const res = await calculateRoute(router, routingParameters)
    const { routes } = res
    const sections = routes[0].sections

    this.renderSummary(sections)
    await this.drawRoute(sections)
    await this.addMarkers(sections)
  }

  drawRoute(sections) {
    const lineStrings = sections.map(({ polyline }) =>
      H.geo.LineString.fromFlexiblePolyline(polyline),
    )

    const multiLineString = new H.geo.MultiLineString(lineStrings)

    const routePolyline = new H.map.Polyline(multiLineString, {
      style: { strokeColor: '#F4211A', lineWidth: 4 },
    })

    this.resetMapElements()

    this.map.addObject(routePolyline)
    this.map.getViewModel().setLookAtData({
      bounds: multiLineString.getBoundingBox(),
    }, true)

    this.routePolyline = routePolyline
  }

  addMarkers(sections) {
    const svg = '<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,16A160,160,0,0,0,96,175.9v.1c0,88.4,160,320,160,320S416,264.4,416,176A160,160,0,0,0,256.1,16Zm0,248a88,88,0,1,1,.1,0Z" style="fill:#f4211a"/><path d="M256,8A168.18,168.18,0,0,0,88,176c0,89.83,154.83,315,161.42,324.54a8,8,0,0,0,13.16,0C269.17,491,424,265.83,424,176A168.18,168.18,0,0,0,256,8Zm0,473.74C226,437.11,104,250.84,104,176a152,152,0,0,1,304,0C408,250.82,286,437.1,256,481.74Z" style="fill:#fff"/><path d="M256,80a96,96,0,1,0,96,96A96,96,0,0,0,256,80Zm0,176a80,80,0,1,1,80-80A80,80,0,0,1,256,256Z" style="fill:#fff"/></svg>'
    const markerIcon = new H.map.Icon(svg)

    const group = new H.map.Group()

    sections.forEach(({ departure, arrival }) => {
      const startMarker = new H.map.Marker(departure.place.location, { icon: markerIcon })
      const endMarker = new H.map.Marker(arrival.place.location, { icon: markerIcon })
      group.addObjects([startMarker, endMarker])
    })

    this.map.addObject(group)
    this.markerGroup = group
  }

  renderSummary(sections) {
    const summary = sections.map(section => section.summary)
    this.controller.dispatch('updateSummary', { target: document, detail: { summary: summary } })
  }

  resetMapElements() {
    if (this.routePolyline && this.markerGroup) {
      this.map.removeObjects([this.routePolyline, this.markerGroup])
      this.routePolyline = null
      this.markerGroup = null
    }
  }

  // Private

  coordinateToString(coordinates) {
    return Object.values(coordinates).join()
  }

  get origin() {
    return this.coordinateToString(this.coordinates[0])
  }

  get via() {
    return this.coordinates.slice(1, -1).map(c => this.coordinateToString(c))
  }

  get destination() {
    return this.coordinateToString(this.coordinates[this.coordinates.length - 1])
  }

  get coordinates() {
    return JSON.parse(this.stopsElement.dataset.coordinates) || []
  }
}