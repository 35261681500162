import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  validate({ target }) {
    if (target.checked) {
      const $checkboxes = document.querySelectorAll(target.dataset.uncheckSelector)
      $checkboxes.forEach(($checkbox) => {
        if ($checkbox.checked) {
          $checkbox.checked = false
          this.dispatch($checkbox.id, {})
        }
      })
    }
  }
}
