export function geocode(searchService, address) {
  return new Promise((resolve, reject) => {
    searchService.geocode({ q: address }, resolve, reject)
  })
}

export function discover(searchService, query) {
  return new Promise((resolve, reject) => {
    searchService.discover({
      q: query,
      limit: 10,
      at: '39.828200,-98.579600',
      in: 'countryCode:USA',
    }, resolve, reject)
  })
}

export function calculateRoute(routerService, options) {
  return new Promise((resolve, reject) => {
    routerService.calculateRoute({ ...options }, resolve, reject)
  })
}