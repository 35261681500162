export function containerSelected(typeTriggerTargets, typeTargets) {
  return typeTriggerTargets.find(container => container.dataset.abbreviation === abbreviationSelected(typeTargets))
}

export function hideTypeTriggerTargets(typeTriggerTargets) {
  typeTriggerTargets.forEach(container => container.classList.add('hidden'))
}

export function abbreviationSelected(typeTargets) {
  const checkedType = typeTargets.find(type => type.checked)
  return checkedType ? checkedType.dataset.abbreviation : null
}

export function constructFileName(name, typeTargets) {
  const attachmentTypeName = abbreviationSelected(typeTargets)
  return `${attachmentTypeName} - ${name}`
}

export function searchAndShowInputAssociated(typeTriggerTargets, typeTargets) {
  hideTypeTriggerTargets(typeTriggerTargets)

  let parentSelected = containerSelected(typeTriggerTargets, typeTargets)  
  if (parentSelected) parentSelected.classList.remove('hidden')
}
